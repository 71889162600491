import MinimalFooter from "./MinimalFooter";
import "./Footer.css";

function FooterBottom() {
  return (
    <>
      <MinimalFooter />
    </>
  );
}

export default FooterBottom;
