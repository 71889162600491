import { RedocStandalone } from "redoc";
import dataSource from "./v3.yaml";
import { useState } from "react";
import FyersLoader from "../../ComponentsGeneral/Loading/Spinner";

export default function Docs() {
  const [loading, setLoading] = useState(true);
  const configuration = {
    hideDownloadButton: true,
    hideInfoSection: true,
    hideLogo: true,
    hideLoading: true,
  };

  return (
    <>
      <RedocStandalone
        specUrl={dataSource}
        options={configuration}
        onLoaded={() => setLoading(false)}
      />
      {loading ? <FyersLoader /> : ""}
    </>
  );
}
