import CONSTANTS from "../Constants/static"

/**
 * [InitializeReactGA Initiates google analytics tracking]
 * @param  {Object} ReactGA
 * @return {void}
 */
 function InitializeReactGA(ReactGA) {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(CONSTANTS.google_analytics_tracking_id);
      window.GA_INITIALIZED = true;
    }
  }
  
  export default InitializeReactGA;