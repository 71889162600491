import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppCard from "./AppCard";
import CreateEditDrawer from "./CreateEditDrawer";
import DeleteModal from "./DeleteModal";
import Spinner from "../../ComponentsGeneral/Loading/Spinner";
import { setApiData, setLoading } from "../../Store/slice/apiListSlice";
import {
  setLogin,
  setUserToken,
  setUserId,
} from "../../Store/slice/isLoggedInSlice";
import { getUserApps, validateCookie } from "../../Services/API/api";
import PROPERTIES from "../../Config/properties";
import fyersToaster from "../../Helpers/fyersToaster";
import destructureError from "../../Helpers/destructureErrorMessage";
import "./Dashboard.css";
import CONSTANTS from "../../Constants/static";

export default function Dashboard() {
  const [createDrawerVisible, setCreateDrawerVisible] = useState(false);
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedApp, setSelectedApp] = useState({});
  const dispatch = useDispatch();
  const appsList = useSelector((state) => state.apiList);
  const loginStatus = useSelector((state) => state.loggedIn);

  async function getApps() {
    dispatch(setLoading(true));
    try {
      const { data } = await getUserApps();
      dispatch(setApiData(data));
    } catch (e) {
      const errorText = destructureError(e);
      const errorMessage = {
        type: "error",
        title: "Error",
        desc: errorText,
      };
      fyersToaster(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }

  async function cookieLogin() {
    try {
      const res = await validateCookie();
      if (res.status === 200) {
        dispatch(setLogin(true));
        const userToken = res.data?.data?.token;
        dispatch(setUserToken(userToken));
        const userId = res.data?.data?.fyId;
        dispatch(setUserId(userId));
        getApps(userToken);
      } else {
        window.location.replace(`${PROPERTIES.login_callback_url}/dashboard`);
      }
    } catch (e) {
      window.location.replace(`${PROPERTIES.login_callback_url}/dashboard`);
    }
  }

  useEffect(() => {
    if (!loginStatus.loggedIn) {
      cookieLogin();
    } else {
      getApps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        <main className="container top-section">
          <div className="text-center p-2 p-md-5">
            <h1 className="py-2">API Apps</h1>
            <p>
              Start Algo trading or integrate with
              <br /> third-party platforms using FYERS API, for absolutely free.
            </p>
            <button
              className="btn btn-primary text-white clear-calculator ml-3 mt-3"
              onClick={() => {setCreateDrawerVisible(true); window.analytics('Create_App',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}}
              disabled={appsList.loading}
            >
              + Create App
            </button>
          </div>
        </main>
        {appsList.loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <>
            <div className="d-flex dashboard-container pb-5">
              {appsList.data.map((apiItem) => {
                return (
                  <AppCard
                    apiItem={apiItem}
                    setSelectedApp={setSelectedApp}
                    setEditDrawerVisible={setEditDrawerVisible}
                    setDeleteModalVisible={setDeleteModalVisible}
                    key={apiItem.appId}
                  />
                );
              })}
            </div>
            {createDrawerVisible && (
              <CreateEditDrawer
                setDrawerVisible={setCreateDrawerVisible}
                getApps={getApps}
                mode="create"
              />
            )}
            {editDrawerVisible && (
              <CreateEditDrawer
                setDrawerVisible={setEditDrawerVisible}
                getApps={getApps}
                mode="edit"
                initialValues={selectedApp}
              />
            )}
            {deleteModalVisible && (
              <DeleteModal
                setModalVisible={setDeleteModalVisible}
                getApps={getApps}
                initialValues={selectedApp}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
