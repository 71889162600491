import axios from "axios";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "./ComponentsGeneral/Navbar/Navbar";
import Footer from "./ComponentsGeneral/Footer/Footer";
import Dashboard from "./Pages/Dashboard/Dashboard";
// import Docs from "./Pages/Docs/Docs";
import Docsv3 from "./Pages/Docs/DocsV3";
import Home from "./Pages/Home/Home";
import { usePageViews } from "./Helpers/pageViews";
import getCookie from "./Helpers/getCookie";
import { Navigate } from "react-router-dom";
import "./App.css";

function App() {
  usePageViews();
  const loginStatus = useSelector((state) => state.loggedIn);
  const cookie = getCookie("_FYERS");

  axios.interceptors.request.use(
    (config) => {
      if (loginStatus.userToken) {
        config.headers["Authorization"] = cookie;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/docs" element={<Navigate to="/docsv3" replace />} />
        <Route exact path="/docsv3" element={<Docsv3 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
