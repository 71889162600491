// import dev from "./dev";
// import staging from "./staging";
import prod from "./prod";

var config = prod;

export default Object.freeze({
  check_cookie_url: config.check_cookie_url,
  login_callback_url: config.login_callback_url,
  base_url: config.base_url,
});
