import axios from "axios";
import PROPERTIES from "../../Config/properties";

/**
 * [validateCookie Validates the existing document cookies for user authentication]
 * @return {Promise}
 */
export const validateCookie = () =>
  axios.post(PROPERTIES.check_cookie_url, {
    cookie: document.cookie,
  });

/**
 * [getUserApps Fetches the user's apps]
 * @param  {Object} config
 * @return {Promise}
 */
export const getUserApps = () => axios.get(`${PROPERTIES.base_url}/user-apps`);

/**
 * [createApp Creates a new app]
 * @param  {Object} payload
 * @return {Promise}
 */
export const createApp = (payload) =>
  axios.post(`${PROPERTIES.base_url}/user-apps`, payload);

/**
 * [editApp Edits an existing app]
 * @param  {Object} payload
 * @return {Promise}
 */
export const editApp = (payload) =>
  axios.put(`${PROPERTIES.base_url}/user-apps`, payload);

/**
 * [deleteApp Deleted the particular app]
 * @param  {Object} payload Contains the app id of the app to be deleted
 * @return {Promise}
 */
export const deleteApp = (payload) =>
  axios.delete(`${PROPERTIES.base_url}/user-apps`, {
    data: payload,
  });
