export default function getCookie(name) {
  var dc, prefix, begin, end;
  dc = document.cookie;
  prefix = name + "=";
  begin = dc.indexOf("; " + prefix);
  end = dc.length;
  if (begin !== -1) {
    begin += 2;
  } else {
    begin = dc.indexOf(prefix);
    if (begin === -1 || begin !== 0) return null;
  }
  if (dc.indexOf(";", begin) !== -1) {
    end = dc.indexOf(";", begin);
  }
  // eslint-disable-next-line
  return decodeURI(dc.substring(begin + prefix.length, end)).replace(/\"/g, "");
}
