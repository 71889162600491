import { createSlice } from "@reduxjs/toolkit";

const initialState = { loggedIn: false, userToken: "", userId: "" };

export const isLoggedInSlice = createSlice({
  name: "isLoggedIn",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.loggedIn = action.payload;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { setLogin, setUserToken, setUserId } = isLoggedInSlice.actions;

export default isLoggedInSlice.reducer;
