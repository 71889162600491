export default Object.freeze({
  Products: [
    {
      name: "Fyers Web",
      img_url: "https://assets.fyers.in/images/website/icon-fyers-web.svg",
      url: "https://trade.fyers.in",
      description: "Web Trading Platform",
      category: "Products",
    },
    {
      name: "Fyers App",
      img_url: "https://assets.fyers.in/images/website/icon-fyers-app.svg",
      url: "https://fyers.in/app/",
      description: "Mobile Trading Platform",
    },
    {
      name: "Fyers One",
      img_url: "https://assets.fyers.in/images/website/icon-fyers-one.svg",
      url: "https://fyers.in/one",
      description: "Desktop Trading Platform",
    },
    {
      name: "Fyers Thematic",
      img_url: "https://assets.fyers.in/images/website/icon-fyers-thematic.svg",
      url: "https://thematic.fyers.in",
      description: "Expert-made Portfolios",
    },
    {
      name: "My Account",
      img_url: "https://assets.fyers.in/images/website/icon-my-account.svg",
      url: "https://myaccount.fyers.in",
      description: "Dashboard to Fyers account",
    },
    {
      name: "Fyers API",
      img_url: "https://assets.fyers.in/images/website/icon-fyers-api.svg",
      url: "https://myapi.fyers.in",
      description: "Enterprise Grade Trading",
      default: true,
    },
    {
      name: "Fyers API Bridge",
      img_url:
        "https://assets.fyers.in/images/website/icon-fyers-api-bridge.svg",
      url: "https://fyers.in/api-bridge",
      description: "Bridge for Algo Trading",
    },
    {
      name: "IPO",
      img_url: "https://assets.fyers.in/images/website/icon-ipo.svg",
      url: "https://ipo.fyers.in",
      description: "Invest in IPO’s easily",
    },
    {
      name: "Fyers Direct",
      img_url: "https://assets.fyers.in/images/website/icon-fyers-direct.svg",
      url: "https://direct.fyers.in",
      description: "Commission free mutual funds",
    },
    {
      name: "FYERS Pledge",
      img_url:
        "https://assets.fyers.in/images/global-header/fyers-pledge-icon.svg",
      url: "https://pledge.fyers.in",
      description: "Get additional margins ",
    },
    {
      name: "OFS",
      img_url: "https://assets.fyers.in/images/website/icon-ofs.svg",
      url: "https://ofs.fyers.in",
      description: "Invest In OFS Seamlessly ",
    },
    {
      name: "SGB",
      img_url: "https://assets.fyers.in/images/website/Fyers-sgb-icon.svg",
      url: "https://sgb.fyers.in/",
      description: "Invest in Sovereign Gold Bond",
    },
  ],
  Tools: [
    {
      name: "Price Alerts",
      img_url: "https://assets.fyers.in/images/website/icon-price-alerts.svg",
      url: "https://alerts.fyers.in",
      description: "Real-Time Updates",
      category: "Tools",
    },
    {
      name: "Insights",
      img_url: "https://assets.fyers.in/images/pledge/logo-insight.svg",
      url: "https://insights.fyers.in",
      description: "Trading widget platform",
    },
    {
      name: "School of stocks",
      img_url: "https://assets.fyers.in/images/pledge/logo-sos.svg",
      url: "https://fyers.in/school-of-stocks",
      description: "Stock market, simplified for everyone",
    },
  ],
  Calculator: [
    {
      name: "Brokerage Calculator",
      img_url:
        "https://assets.fyers.in/images/website/icon-brokerage-calculator.svg",
      url: "https://fyers.in/brokerage-calculator",
      category: "Calculators",
    },
    {
      name: "Margin Calculator",
      img_url:
        "https://assets.fyers.in/images/website/icon-margin-calculator.svg",
      url: "https://fyers.in/margin-calculator/equity-margin/",
    },
  ],
  Updates: [
    {
      name: "Notice Board",
      img_url: "https://assets.fyers.in/images/pledge/logo-noticeboard.svg",
      url: "https://fyers.in/notice-board",
      description: "Important announcements",
      category: "Updates",
    },
    {
      name: "Holiday Calender",
      img_url:
        "https://assets.fyers.in/images/pledge/logo-holiday-calender.svg",
      url: "https://fyers.in/holiday-calendar",
      description: "Stock market holidays",
    },
  ],
});
