import { Button, Dropdown, Menu } from "antd";
import moment from "moment";
import _ from "lodash";
import IMAGES from "../../Constants/images";

export default function AppCard({
  apiItem,
  setSelectedApp,
  setEditDrawerVisible,
  setDeleteModalVisible,
}) {
  const hideSecretId = apiItem.appType !== 100;
  function getAppPermission(permissionKeys) {
    let permissions = [];
    if (permissionKeys.includes("x:2"))
      permissions.push("Profile Details, Transaction Info, Order Placement");
    else if (permissionKeys.includes("x:1"))
      permissions.push("Profile Details, Transaction Info");
    else if (permissionKeys.includes("x:0"))
      permissions.push("Profile Details");

    if (permissionKeys.includes("d:2"))
      permissions.push(" Historical Data, Quotes & Market data");
    else if (permissionKeys.includes("d:1"))
      permissions.push(" Quotes & Market data");
    if (_.isEmpty(permissions)) permissions.push("No Permissions");

    return permissions.toString();
  }

  function userOptions(apiData) {
    let optionItems = [];
    if (apiData.appType === 100) {
      optionItems.push({
        key: "edit",
        label: (
          <Button
            onClick={() => {
              setSelectedApp(apiData);
              setEditDrawerVisible(true);
            }}
            className="dropdownButton"
          >
            <img
              src={IMAGES.edit_app_icon}
              className="mb-1"
              width="22"
              alt=""
            />
            <span className="mb-1 ml-2 item-description fyers-dark">Edit</span>
          </Button>
        ),
      });
    }
    optionItems.push({
      key: "delete",
      label: (
        <Button
          onClick={() => {
            setSelectedApp(apiData);
            setDeleteModalVisible(true);
          }}
          className="dropdownButton"
        >
          <img
            src={IMAGES.delete_app_icon}
            className="mb-1"
            width="22"
            alt=""
          />
          <span className="mb-1 ml-2 item-description fyers-dark">Delete</span>
        </Button>
      ),
    });
    return <Menu items={optionItems} />;
  }

  return (
    <div className="container">
      <div className="row no-gutters">
        <div className="col-md-12 align-self-center">
          <div className="post mx-3 mx-md-0">
            <div className="row pr-md-4 py-3 my-3 tab-click ">
              <div className="col d-md-block pr-lg-4 pl-lg-4 py-3 w-40">
                <div className="d-flex justify-content-between justify-content-md-start">
                  <div className="mr-2">
                    <img
                      src={
                        apiItem.image ? apiItem.image : IMAGES.default_app_icon
                      }
                      alt={apiItem.appName}
                      width={100}
                      height={100}
                    />
                  </div>
                  <div>
                    <div className="my-3 mb-2 ml-3 d-flex align-items-center flex-wrap">
                      <h4 className="mr-2 mb-0">{apiItem.appName}</h4>
                      {apiItem.status === 1 ? (
                        <span className="user-app-status px-3 active-app mt-sm-3 mt-md-0">
                          Active
                        </span>
                      ) : (
                        <span className="user-app-status px-3 inactive-app mt-sm-3 mt-md-0">
                          Inactive
                        </span>
                      )}
                    </div>
                    <div className="mb-2 ml-3">
                      <p className="text-break">
                        {apiItem.description
                          ? apiItem.description
                          : "No description"}
                      </p>
                    </div>
                    <div className="d-flex date-container flex-wrap flex-column">
                      <div className="mb-2 ml-3 text-nowrap">
                        <span>Created : </span>
                        <img
                          src={IMAGES.calender_small_icon}
                          alt="Created date"
                          height="12"
                          className="mb-1 mr-1"
                        />{" "}
                        <span className="fyers-dark">
                          {" "}
                          {moment.unix(apiItem.createdOn).format("DD MMM YYYY")}
                        </span>
                      </div>{" "}
                      <div className="mb-2 ml-3 text-nowrap">
                        <span>Updated : </span>
                        <img
                          src={IMAGES.calender_small_icon}
                          alt="Updated date"
                          height="12"
                          className="mb-1 mr-1"
                        />{" "}
                        <span className="fyers-dark">
                          {" "}
                          {moment.unix(apiItem.updatedOn).format("DD MMM YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 mb-2 ml-3">
                      <a
                        href={apiItem.redirectUrl
                          .replaceAll("*", "")
                          .replaceAll("/auth", "")}
                        className="text-blue"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Visit {apiItem.appName}
                        <img
                          className="p-1 align-middle"
                          src={IMAGES.next_link_icon}
                          alt="Visit app"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="options-button d-md-none">
                    <Dropdown
                      overlay={() => userOptions(apiItem)}
                      trigger={["click"]}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placement="bottomRight"
                    >
                      <span
                        id="bd-versions"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={IMAGES.options_dot_icon} alt="Show Options" />
                      </span>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-sm info-container d-flex flex-column justify-content-around pl-lg-5 pt-2">
                <div className="d-flex justify-content-between mt-3">
                  <div className="col d-flex flex-row flex-wrap pl-0">
                    <div className="col-lg-4">
                      <p className="col-sm-4 mb-3 d-flex flex-column">
                        <span className="text-nowrap font-weight-normal">
                          App type:
                        </span>
                        <span className="text-nowrap mt-1 item-description fyers-dark">
                          {apiItem.appType === 100
                            ? "User App"
                            : apiItem.appType === 101
                            ? "Fyers App"
                            : apiItem.appType === 102
                            ? "Third Party App"
                            : "Unspecified"}
                        </span>
                      </p>
                    </div>
                    <div className="col-lg-4">
                      {" "}
                      <p className="col-sm-4 mb-3 d-flex flex-column">
                        <span className="text-nowrap font-weight-normal">
                          Webhook count:
                        </span>
                        <span className="text-nowrap mt-1 item-description fyers-dark">
                          {apiItem.webhook ? apiItem.webhook.length : "0"}
                        </span>
                      </p>
                    </div>
                    <div className="col-lg-4">
                      {" "}
                      <p className="col-sm-4 mb-3 d-flex flex-column">
                        <span className="text-nowrap font-weight-normal">
                          App ID:
                        </span>
                        <span className="text-nowrap mt-1 item-description fyers-dark">
                          {apiItem.appId}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="options-button d-none d-md-block">
                    <Dropdown
                      overlay={() => userOptions(apiItem)}
                      trigger={["click"]}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placement="bottomRight"
                    >
                      <span
                        id="bd-versions"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={IMAGES.options_dot_icon} alt="Show Options" />
                      </span>
                    </Dropdown>
                  </div>
                </div>
                <div className="col d-flex flex-row mt-lg-4 pl-0">
                  {hideSecretId ? (
                    <></>
                  ) : (
                    <div className="col-lg-4">
                      {" "}
                      <p className="col-sm-4 mb-3 d-flex flex-column">
                        <span className="text-nowrap font-weight-normal">
                          Secret ID:
                        </span>
                        <span className="text-nowrap mt-1 item-description fyers-dark">
                          {apiItem.secretId}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className={`${hideSecretId ? "col-lg-12" : "col-lg-8"}`}>
                    {" "}
                    <p className="mb-3 d-flex flex-column w-100 col-sm-12">
                      <span className="text-nowrap font-weight-normal">
                        Permissions:
                      </span>
                      <span className="mt-1 item-description fyers-dark">
                        {getAppPermission(apiItem.permissions)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
