import React from "react";
import { useNavigate, Link } from "react-router-dom";
import CONSTANTS from '../../Constants/static';

export default function Home() {
  const navigate = useNavigate();
  return (
    <div id="homepage-container">
      <section className="blue-bg-gradient pb-lg-5 pb-3">
        <main className="container pb-lg-5 pb-3">
          <div className="row pt-md-5 pb-lg-5 pb-3">
            <div className="col-lg-7 col-md-6 order-md-1 mt-md-0 mt-5 order-md-1 order-2">
              <h1 className="py-3">Enterprise Grade Trading APIs</h1>
              <div className="pr-lg-5 mr-lg-5 ">
                <p className="pr-md-5">
                  Build your own innovative platforms or deploy algorithmic
                  trading strategies that suits your unique requirements using
                  our reliable trading APIs
                </p>
              </div>
              <button
                onClick={() => {navigate("/dashboard"); window.analytics('Get_Started',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}}
                className="btn btn-primary btn-lg"
              >
                Get Started
              </button>
            </div>
            <div className="col-lg-5 col-md-6 mb-4 mb-md-0 order-md-2  mt-lg-0 mt-3 order-md-2 order-1 ">
              <img
                src="https://assets.fyers.in/images/Fyers-Myapi/illustration-corporate.svg"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </main>
      </section>
      <section className="py-5 bg-white">
        <div className="container pt-md-4 mt-md-3">
          <h2 className="text-center py-3 py-md-5 mb-0 mb-md-0">
            Why Fyers API’s ?
          </h2>
          <div className="px-2">
            <div className="row justify-content-around text-center">
              <div className="col-md-4 col-lg-3  p-3 mb-md-3">
                <img
                  src="https://assets.fyers.in/images/Fyers-Myapi/icon-free.svg"
                  alt="Free"
                />
                <h4 className="py-3 mb-0">Free</h4>
                <p>Our trading APIs at absolutely Zero fees.</p>
              </div>
              <div className="col-md-4 col-lg-3  p-3 mb-md-3">
                <img
                  src="https://assets.fyers.in/images/Fyers-Myapi/icon-robust.svg"
                  alt="Robust"
                />
                <h4 className="py-3 mb-0">Robust</h4>
                <p>
                  Tested by third-party trading system and is built for highly
                  scalable workloads.
                </p>
              </div>
              <div className="col-md-4 col-lg-3  p-3 mb-md-3">
                <img
                  src="https://assets.fyers.in/images/Fyers-Myapi/icon-simple.svg"
                  alt="Simple"
                />
                <h4 className="py-3 mb-0">Simple</h4>
                <p>
                  Extremely simple to integrate through extensive documentation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 bg-white">
        <div className="container">
          <h2 className="text-center py-3 py-md-5 mb-0 mb-md-0">
            Who is it meant for?
          </h2>
          <div className="px-2">
            <div className="row justify-content-around">
              <div className="col-md-4 col-lg-3 p-3 mb-md-3">
                <img
                  className="mh-55"
                  src="https://assets.fyers.in/images/Fyers-Myapi/icon-algorthmic-trading.svg"
                  alt="Algorithmic trading"
                />
                <h4 className="pt-3">Algorithmic trading</h4>
                <p>
                  Serious traders who want to automate their trading system.
                </p>
              </div>
              <div className="col-md-4 col-lg-3 p-3 mb-md-3">
                <img
                  className="mh-55"
                  src="https://assets.fyers.in/images/Fyers-Myapi/icon-platforms.svg"
                  alt="Platforms"
                />
                <h4 className="pt-3">Platforms</h4>
                <p>
                  Softwares &amp; websites that cater to the trading/investing
                  audience.
                </p>
              </div>
              <div className="col-md-4 col-lg-3 p-3 mb-md-3">
                <img
                  className="mh-55"
                  src="https://assets.fyers.in/images/Fyers-Myapi/icon-startups.svg"
                  alt="Startups"
                />
                <h4 className="pt-3">Startups</h4>
                <p>
                  Teams that desire to build innovative trading/investing
                  platforms to expand the capital markets ecosystem
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 mb-5 bg-white">
        <h2 className="container text-center py-md-5">
          Various Integration Methods
        </h2>
        <div className="row no-gutters justify-content-center">
          <div className="col-md-auto col-sm-12 p-lg-5 p-md-4 p-3 mb-md-3 d-flex flex-column align-items-center">
            <img
              className="mw-64 pb-1"
              src="https://assets.fyers.in/images/Fyers-Myapi/python.svg"
              alt="Python"
            />
            <p className="p-2 mb-md-3 mt-3 text-center">Python</p>
          </div>
          <div className="col-md-auto col-sm-12 p-lg-5 p-md-4 p-3 mb-md-3 d-flex flex-column align-items-center">
            <img
              className="mw-64 pb-1"
              src="https://assets.fyers.in/images/Fyers-Myapi/rest.svg"
              alt="Rest"
            />
            <p className="p-2 mb-md-3 mt-3 text-center">Rest</p>
          </div>
          <div className="col-md-auto col-sm-12 p-lg-5 p-md-4 p-3 mb-md-3 d-flex flex-column align-items-center">
            <img
              className="mw-64 mb-2"
              src="https://assets.fyers.in/images/Fyers-Myapi/node-js.svg"
              alt="Node JS"
            />
            <p className="p-2 mb-md-3 mt-3 text-center">Node js</p>
          </div>
          <div className="col-md-auto col-sm-12 p-lg-5 p-md-4 p-3 mb-md-3 d-flex flex-column align-items-center">
            <img
              className="mw-64 mb-2"
              src="https://assets.fyers.in/images/Fyers-Myapi/api-connect.svg"
              alt="My API Connect"
            />
            <p className="p-2 mb-md-3 mt-3 text-center">
              My API <br className="d-md-block d-none" />
              Connect
            </p>
          </div>
        </div>
        <Link
          to="/docs/"
          className="font-weight-bold d-flex justify-content-center"
          onClick={()=>window.analytics('Read_detailed_API_documentation',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
        >
          Read detailed API documentation{" "}
          <img
            src="https://assets.fyers.in/images/website/icon-link-next.svg"
            className="pl-2"
            alt=""
          />
        </Link>
      </section>
      <section className="my-md-5 my-3 py-4">
        <div className="bg-light-blue p-1 p-md-4">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-md">
                <h2 className="pr-md-5">Fyers community</h2>
                <p className="mb-0">
                  Interact with the fellow traders &amp; have quality
                  discussions
                  <br className="d-none d-lg-block" /> about interesting topics
                  related to the capital <br className="d-none d-lg-block" />
                  markets on our popular forum.
                </p>
              </div>
              <div className="col-md-4 offset-md-1 mt-5 mt-md-0 text-lg-right">
                <a
                  href="https://community.fyers.in/fyers-api-rha0riqv"
                  className="btn btn-lg btn-primary"
                  target="_blank"
                  rel="noreferrer"
                  onClick={()=>window.analytics('Visit_Fyers_Community',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
                >
                  Visit Fyers Community
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
