import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, NavLink } from "react-router-dom";
import {
  setLogin,
  setUserToken,
  setUserId,
} from "../../Store/slice/isLoggedInSlice";
import { Dropdown, Menu, Button } from "antd";
import { validateCookie } from "../../Services/API/api";
import PROPERTIES from "../../Config/properties";
import CONSTANTS from "../../Constants/static";
import deleteCookie from "../../Helpers/deleteCookie";
import ProductModal from "./ProductModal";
import Prods from "../../Constants/productList";
import IMAGES from "../../Constants/images";
import images from "../../Constants/images";
import "./Navbar.css";

export default function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [productMenuVisible, setProductMenuVisible] = useState(false);
  const [productState, setProductState] = useState(false);
  const [calculator, setCalculator] = useState(false);
  const [tools, setTools] = useState(false);
  const [updates, setUpdates] = useState(false);
  const loginStatus = useSelector((state) => state.loggedIn);

  useEffect(() => {
    async function checkLogin() {
      try {
        const res = await validateCookie();
        if (res.status === 200) {
          dispatch(setLogin(true));
          const userToken = res.data?.data?.token;
          dispatch(setUserToken(userToken));
          const userId = res.data?.data?.fyId;
          dispatch(setUserId(userId));
        }
      } catch {}
    }
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleLogout() {
    deleteCookie("_FYERS");
    navigate("/");
    dispatch(setLogin(false));
  }

  const subMenu = (menu) => {
    return menu.map((item, index) => {
      return (
        <li key={index}>
          <a href={item.url} target="_blank" rel="noreferrer">
            <span className="icon_ig">
              <img src={item.img_url} alt="app url" width={48}></img>
            </span>
          </a>
          {item.name}
        </li>
      );
    });
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Button
              onClick={()=>{
                handleLogout();
                window.analytics('Logout',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)
              }}
              className="logout-icon1 logout-text my-1"
            >
              <img
                src={IMAGES.navbar_logout_icon}
                width=""
                alt=""
                className="mb-1 mr-2 logout-image"
              />{" "}
              Logout
            </Button>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <header className=" border-bottom py-2 py-lg-0 bd-navbar sticky-top bg-white ">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <Link className="mr-5" to="/" onClick={()=>window.analytics('Logo',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}>
              {" "}
              <img src={IMAGES.myapi_logo} height="50" alt="FYERS MyAPI" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="" onClick={() => {setMobileMenuVisible(true); window.analytics('Mobile_Menu',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}}>
              <img
                src={IMAGES.navbar_hamburger_menu}
                width="25"
                alt="Open Menu"
                className="mb-1 mr-2 logout-icon"
              />{" "}
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto ml-4">
                <li className="nav-item py-1 px-2">
                  <NavLink
                    to="dashboard"
                    className={({ isActive }) =>
                      `nav-link py-4 ${isActive ? "active" : "inactive"}`
                    }
                    onClick={()=>window.analytics('Dashboard',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
                  >
                    DASHBOARD
                  </NavLink>
                </li>
                {/* <li className="nav-item py-1 px-2" title="View v2 docs">
                  <NavLink
                    to="docs"
                    className={({ isActive }) =>
                      `nav-link py-4 ${isActive && "active"}`
                    }
                    onClick={()=>window.analytics('V2_Docs',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
                  >
                    V2 DOCS
                  </NavLink>
                </li> */}
                <li className="nav-item py-1 px-2" title="View v3 docs">
                  <NavLink
                    to="docsv3"
                    className={({ isActive }) =>
                      `nav-link py-4  ${isActive && "active"}`
                    }
                    onClick={()=> window.analytics('V3_Docs',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
                  >
                    <div className="v3-new-link-wrapper">
                      {" "}
                      V3 DOCS <span className="new-badge px-1">NEW</span>
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item py-1 px-2">
                  <a
                    className="nav-link py-4"
                    href="https://api-connect-docs.fyers.in/"
                    onClick={()=>window.analytics('API_Connect',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
                  >
                    API CONNECT
                  </a>
                </li>
              </ul>
              {loginStatus.loggedIn ? (
                <ul className="navbar-nav ml-md-auto d-md-flex">
                  <li className="navbar-user-text">
                    <Dropdown
                      placement="bottom"
                      arrow
                      overlay={menu}
                      trigger={["hover"]}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <span
                        className="nav-item nav-link dropdown-toggle"
                        id="bd-versions"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={IMAGES.navbar_user_icon}
                          width="28"
                          alt=""
                          className="mb-1 mr-2"
                        />
                        &nbsp;<span>{loginStatus.userId} </span>
                        &nbsp;
                        <span className="drp_btn">
                          <img
                            alt="down arrow"
                            className="nav-arrow-rotate logout-icon1"
                            src={IMAGES.navbar_down_arrow_icon}
                          />
                        </span>
                      </span>
                    </Dropdown>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav">
                  <li className="nav-item mr-2">
                    <a
                      className="nav-link  sign-in-button"
                      href={PROPERTIES.login_callback_url}
                      onClick={()=>window.analytics('Sign_In',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
                    >
                      SIGN IN
                    </a>
                  </li>
                  <li className="nav-item mr-2 ">
                    <a
                      className="btn btn-outline-primary nav-link text-primary sign-up-button"
                      href={CONSTANTS.sign_up_url}
                      style={{ color: "#436af5" }}
                      onClick={()=>window.analytics('Sign_Up_Now',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
                    >
                      Sign Up Now
                    </a>
                  </li>
                </ul>
              )}
            </div>
            <div
              className="productMenu"
              onClick={() => {setProductMenuVisible(true); window.analytics('Product_Menu',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}}
            >
              <img
                src={IMAGES.navbar_grid_icon}
                width="25"
                alt=""
                className="mb-1 mr-2"
              />{" "}
            </div>
          </nav>
          {mobileMenuVisible && (
            <div className="mobile_menu">
              <div className="d-flex justify-content-between user-info-container">
                {loginStatus.loggedIn && (
                  <li className="font-weight-bold d-flex align-items-center">
                    <img
                      src={IMAGES.navbar_user_icon}
                      width="25"
                      alt=""
                      className="mr-2"
                    />{" "}
                    {loginStatus.userId}
                  </li>
                )}

                <div
                  className="ml-auto"
                  onClick={() => setMobileMenuVisible(false)}
                >
                  <img
                    src={images.close_menu_icon}
                    width="25"
                    alt=""
                    className="mb-1 mr-2 logout-icon"
                  />{" "}
                </div>
              </div>
              <ul className="menu">
                <li>
                  <Link to="dashboard">DASHBOARD</Link>
                </li>
                <li>
                  <Link to="docs">DOCS</Link>
                </li>
                <li>
                  <Link to="docsv3">V3 DOCS</Link>
                </li>
                <li>
                  <a href="https://api-connect-docs.fyers.in/">API CONNECT</a>
                </li>
                {/* copied element */}
                <li>
                  <a
                    href="/#"
                    className="drop-btn"
                    onClick={() => setProductState(!productState)}
                  >
                    PRODUCTS
                    <span
                      className={
                        productState ? "nav-arrow-rotate drp_btn" : "drp_btn"
                      }
                    >
                      <img
                        alt="products arrow"
                        src={IMAGES.navbar_down_arrow_icon}
                      />
                    </span>
                  </a>
                  <div className={productState ? "sub_menu" : "d-none"}>
                    <ul>{subMenu(Prods["Products"])}</ul>
                  </div>
                </li>
                <li>
                  <a
                    href="/#"
                    className="drop-btn"
                    onClick={() => setTools(!tools)}
                  >
                    TOOLS
                    <span
                      className={tools ? "nav-arrow-rotate drp_btn" : "drp_btn"}
                    >
                      <img
                        alt="tools arrow"
                        src={IMAGES.navbar_down_arrow_icon}
                      />
                    </span>
                  </a>
                  <div className={tools ? "sub_menu" : "d-none"}>
                    <ul>{subMenu(Prods["Tools"])}</ul>
                  </div>
                </li>
                <li>
                  <a
                    href="/#"
                    className="drop-btn"
                    onClick={() => setCalculator(!calculator)}
                  >
                    CALCULATORS
                    <span
                      className={
                        calculator ? "nav-arrow-rotate drp_btn" : "drp_btn"
                      }
                    >
                      <img
                        alt="calculator arrow"
                        src={IMAGES.navbar_down_arrow_icon}
                      />
                    </span>
                  </a>
                  <div className={calculator ? "sub_menu" : "d-none"}>
                    <ul>{subMenu(Prods["Calculator"])}</ul>
                  </div>
                </li>
                <li>
                  <a
                    href="/#"
                    className="drop-btn"
                    onClick={() => setUpdates(!updates)}
                  >
                    UPDATES
                    <span
                      className={
                        updates ? "nav-arrow-rotate drp_btn" : "drp_btn"
                      }
                    >
                      <img
                        alt="updates arrow"
                        src={IMAGES.navbar_down_arrow_icon}
                      />
                    </span>
                  </a>
                  <div className={updates ? "sub_menu" : "d-none"}>
                    <ul>{subMenu(Prods["Updates"])}</ul>
                  </div>
                </li>
                {/* till here */}
                {!loginStatus.loggedIn && (
                  <li>
                    <a href={PROPERTIES.login_callback_url}>SIGN IN</a>
                  </li>
                )}
              </ul>
              {loginStatus.loggedIn ? (
                <div className="sign_btn">
                  <button className="btn w-100" onClick={()=>{handleLogout(); window.analytics('Logout',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}}>
                    Logout
                  </button>
                </div>
              ) : (
                <div className="sign_btn">
                  <a href="https://fyers.in/signup.php" className="btn" onClick={()=>window.analytics('Sign_Up',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}>
                    Sign Up Now
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </header>
      {productMenuVisible && (
        <div className="product_menu">
          <ProductModal
            setProductMenuOpen={setProductMenuVisible}
            setMobileMenuVisible={setMobileMenuVisible}
            loginStatus={loginStatus}
            id={loginStatus.userId}
          />
        </div>
      )}
    </>
  );
}
