import React, { useState } from "react";
import { Modal } from "antd";
import "./ProductModal.css";
import _ from "lodash";
import productList from "../../Constants/productList";
import IMAGES from "../../Constants/images";

const ProductModal = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const handleCancel = () => {
    props.setProductMenuOpen(false);
  };

  const products = Object.keys(productList)
    .map((key) => productList[key])
    .flat();

  const filteredProducts = products.filter(
    (product) =>
      product.name
        ?.toLowerCase()
        .replace(/\s/g, "")
        .includes(searchValue.toLowerCase().replace(/\s/g, "")) ||
      product.description
        ?.toLowerCase()
        .replace(/\s/g, "")
        .includes(searchValue.toLowerCase().replace(/\s/g, ""))
  );

  return (
    <>
      <Modal
        open={true}
        onCancel={handleCancel}
        footer={null}
        width={950}
        closable={false}
        className="modal-dialog modal-lg"
      >
        <div className="product-menu-holder h-100 ">
          <div
            className="d-flex justify-content-between"
            id="#productMenuCenter"
          >
            <div className="search-holder">
              <input
                type="search"
                className="product-search-input form-control"
                name="_search"
                id="_search"
                placeholder="Search here..."
                maxLength="20"
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
              />
            </div>
            <button
              type="button"
              className="close-button pl-4"
              onClick={handleCancel}
            >
              <img
                src={IMAGES.navbar_product_close_icon}
                width="25"
                alt="Close menu"
              />
            </button>
          </div>
          {!_.isEmpty(filteredProducts) ? (
            <div id="productMenuItems" className="product-menu-hold">
              <div className="product-menu-item">
                {!searchValue ? (
                  <ul className="d-flex list-unstyled flex-wrap align-items-end">
                    {products.map((filteredProduct, index) => (
                      <React.Fragment key={index}>
                        {filteredProduct.category &&
                        filteredProduct.category !== "Products" ? (
                          <li className="menu-heading m-0 title p-0"></li>
                        ) : (
                          ""
                        )}
                        <li
                          className={`title ${
                            filteredProduct.name === "Fyers API"
                              ? "default-product-app mb-3"
                              : ""
                          }`}
                          key={index}
                        >
                          {filteredProduct.category ? (
                            <h6 className="mt-3">{filteredProduct.category}</h6>
                          ) : (
                            ""
                          )}
                          <a
                            href={filteredProduct.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="icon_ig">
                              <img
                                src={filteredProduct.img_url}
                                alt="fyers-web-icon"
                                className="icon-mobile"
                                width={48}
                              />
                            </span>
                            <span>
                              <span className="hover-arrow product-modal-item white_space d-flex">
                                {filteredProduct.name}
                                <img
                                  src={IMAGES.navbar_product_arrow_icon}
                                  alt="Arrow"
                                />
                              </span>
                              <p>{filteredProduct.description}</p>
                            </span>
                          </a>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                ) : (
                  <ul className="d-flex list-unstyled flex-wrap align-items-end">
                    {filteredProducts.map((filteredProduct, index) => (
                      <li className="title" key={index}>
                        <a
                          href={filteredProduct.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon_ig">
                            <img
                              src={filteredProduct.img_url}
                              alt="fyers-web-icon"
                              className="icon-mobile"
                              width={48}
                            />
                          </span>
                          <span className="hover-arrow product-modal-item white_space">
                            {filteredProduct.name}
                            <img
                              src={IMAGES.navbar_product_arrow_icon}
                              alt="Arrow"
                              className="pt-1"
                            />
                            <p>{filteredProduct.description}</p>
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ) : (
            <div
              id="productMenuEmpty"
              className="no-data-available align-items-center justify-content-center d-flex"
            >
              <div className="pb-5">
                <img
                  src={IMAGES.navbar_product_empty}
                  alt="No Products Available"
                />
                <h5>No result available!</h5>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
export default ProductModal;
