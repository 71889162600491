/**
 * [destructureError Destructures message from error object]
 * @param  {Object} error Error response object recieved from backend
 * @return {String} Returns destructured message if available in response, otherwise returns default message
 */
export default function destructureError(error) {
  if (error.response?.data?.message) {
    return error.response?.data?.message;
  } else {
    return "An error occurred. Please try again.";
  }
}
