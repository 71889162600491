import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], loading: false };

export const apiListSlice = createSlice({
  name: "apiList",
  initialState,
  reducers: {
    setApiData: (state, action) => {
      state.data = action.payload.data;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setApiData, setLoading } = apiListSlice.actions;

export default apiListSlice.reducer;
