import { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Row,
  Collapse,
  Select,
  Dropdown,
  Menu,
  Checkbox,
  Input,
  Upload,
} from "antd";
import { useDispatch } from "react-redux";
import fyersToaster from "../../Helpers/fyersToaster";
import { setLoading } from "../../Store/slice/apiListSlice";
import destructureError from "../../Helpers/destructureErrorMessage";
import { createApp, editApp } from "../../Services/API/api";
import IMAGES from "../../Constants/images";
import CONSTANTS from "../../Constants/static";

function CreateEditDrawer({ setDrawerVisible, getApps, mode, initialValues }) {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { Panel } = Collapse;
  const { TextArea } = Input;

  const beforeUpload = (file) => {
    const isAllowedFormat =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg+xml";

    if (!isAllowedFormat) {
      const errorMessage = {
        type: "error",
        title: "Error",
        desc: "You can only upload JPG, PNG or SVG file!",
      };
      fyersToaster(errorMessage);
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      const errorMessage = {
        type: "error",
        title: "Error",
        desc: "Image must smaller than 5MB!",
      };
      fyersToaster(errorMessage);
    }
    return isAllowedFormat && isLt2M;
  };

  const imageProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: ".jpeg,.png,.svg",
    beforeUpload: beforeUpload,
    onChange(info) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(info.file.originFileObj);
      fileReader.onloadend = async function (event) {
        setImgSrc(fileReader.result);
      };
    },
  };

  async function handleCreateSubmit(values) {
    setDrawerVisible(false);
    dispatch(setLoading(true));
    const structuredWebhooks = values.webhook
      ? values.webhook
          .filter((element) => element) // Removes empty elements
          .map((webhook) => {
            return {
              WEBHOOK_PREFERENCE: webhook.WEBHOOK_PREFERENCE,
              WEBHOOK_SECRET: webhook.WEBHOOK_SECRET,
              WEBHOOK_URL: webhook.WEBHOOK_URL,
            };
          })
      : [];
    try {
      const payload = {
        appName: values.appName,
        description: values.description ? values.description : "",
        permissions: selectedPermissions,
        redirectUrl: values.redirectUrl,
        webhook: structuredWebhooks ? structuredWebhooks : "",
        image: imgSrc ? imgSrc : null,
      };
      const res = await createApp(payload);
      const successMessage = {
        type: "success",
        title: "Success",
        desc: res.data?.message,
      };
      fyersToaster(successMessage);
      getApps();
    } catch (error) {
      const errorText = destructureError(error);
      const errorMessage = {
        type: "error",
        title: "Error",
        desc: errorText,
      };
      fyersToaster(errorMessage);
      dispatch(setLoading(false));
    }
  }

  async function handleEditSubmit(values) {
    setDrawerVisible(false);
    dispatch(setLoading(true));
    const structuredWebhooks = values.webhook
      ? values.webhook
          .filter((element) => element) // Removes empty elements
          .map((webhook) => {
            return {
              WEBHOOK_PREFERENCE: webhook.WEBHOOK_PREFERENCE,
              WEBHOOK_SECRET: webhook.WEBHOOK_SECRET,
              WEBHOOK_URL: webhook.WEBHOOK_URL,
            };
          })
      : [];
    try {
      const payload = {
        appId: initialValues.appId,
        appName: values.appName,
        description: values.description,
        generatePassword: values.generatePassword ? true : false,
        redirectUrl: values.redirectUrl,
        webhook: structuredWebhooks ? structuredWebhooks : [],
        image: imgSrc ? imgSrc : null,
        img_delete: false,
      };
      await editApp(payload);
      const successMessage = {
        type: "success",
        title: "Success",
        desc: `Successfully edited ${values.appName}`,
      };
      fyersToaster(successMessage);
      getApps();
    } catch (error) {
      const errorText = destructureError(error);
      const errorMessage = {
        type: "error",
        title: "Error",
        desc: errorText,
      };
      fyersToaster(errorMessage);
      dispatch(setLoading(false));
    }
  }

  function handlePermissionsChange(data) {
    let permission = [];
    if (data.includes("x:2")) {
      permission.push("x:0", "x:1", "x:2");
    } else if (data.includes("x:1")) {
      permission.push("x:0", "x:1");
    } else if (data.includes("x:0")) {
      permission.push("x:0");
    }
    if (data.includes("d:2")) {
      permission.push("d:1", "d:2");
    } else if (data.includes("d:1")) {
      permission.push("d:1");
    }
    setSelectedPermissions(permission);
  }

  function panelOptions(remove, name) {
    return (
      <div className="options-button">
        <Dropdown
          overlay={() => (
            <Menu
              items={[
                {
                  key: "edit",
                  label: (
                    <Button
                      className="dropdownButton"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(name);
                      }}
                    >
                      <img
                        src={IMAGES.delete_app_icon}
                        className="mb-1"
                        width="22"
                        alt=""
                      />
                      <span className="mb-1 ml-2 item-description fyers-dark">
                        Delete
                      </span>
                    </Button>
                  ),
                },
              ]}
            />
          )}
          trigger={["click"]}
          getPopupContainer={(trigger) => trigger.parentNode}
          placement="bottomRight"
        >
          <span
            onClick={(e) => e.stopPropagation()}
            id="bd-versions"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src={IMAGES.options_dot_icon} alt="Show Options" height={15} />
          </span>
        </Dropdown>
      </div>
    );
  }

  const selectWork = [
    { label: "Cancelled", value: "1" },
    { label: "Traded", value: "2" },
    { label: "Rejected", value: "5" },
    { label: "Pending", value: "6" },

    { label: "Cancelled,Traded", value: "[1, 2]" },
    { label: "Cancelled,Rejected", value: "[1, 5]" },
    { label: "Cancelled,Pending", value: " [1, 6]" },

    { label: "Traded,Rejected", value: "[2, 5]" },
    { label: "Traded,Pending", value: "[2, 6]" },
    { label: "Traded,Cancelled", value: "[2, 1]" },

    { label: "Rejected,Pending", value: "[5, 6]" },
    { label: "Rejected,Traded", value: " [5, 2]" },
    { label: "Rejected,Cancelled", value: "[5, 1]" },

    { label: "Pending,Rejected", value: "[6, 5]" },
    { label: "Pending,Traded", value: "[6, 2]" },
    { label: "Pending,Cancelled", value: "[6, 1] " },

    { label: "Pending,Rejected,Cancelled", value: "[6, 5, 1]" },
    { label: "Pending,Rejected,Cancelled,Traded", value: "[6, 5, 1, 2]" },
  ];

  function customExpandIcon({ isActive }) {
    if (isActive) {
      return <img src={IMAGES.down_arrow_icon} alt="down arrow" />;
    } else
      return <img src={IMAGES.down_arrow_inactive_icon} alt="down arrow" />;
  }

  return (
    <Drawer
      title={`${mode === "create" ? "Create App" : "Edit App Details"}`}
      width={window.innerWidth > 900 ? 580 : window.innerWidth - 100}
      onClose={() => setDrawerVisible(false)}
      open={true}
      bodyStyle={{
        paddingBottom: 80,
      }}
      footer={
        <div className="container d-flex justify-content-between ">
          <button
            className="btn btn-outline-primary text-primary"
            onClick={() => setDrawerVisible(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={() => {form.submit(); window.analytics(`${mode === "create" ? "Create_App_Confirm" : "Edit_Save_Changes"}`,CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}}>
            {mode === "create" ? "Create App" : "Save Changes"}
          </button>
        </div>
      }
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        className="container form-container"
        onFinish={mode === "create" ? handleCreateSubmit : handleEditSubmit}
        autoComplete="off"
        initialValues={
          mode === "edit"
            ? {
                appName: initialValues.appName,
                redirectUrl: initialValues.redirectUrl,
                description: initialValues.description,
                webhook: initialValues.webhook,
              }
            : {}
        }
      >
        <p className="item-description">Enter the app details</p>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="imageURL"
              rules={[
                {
                  required: false,
                  message: "Please upload an Image",
                },
              ]}
            >
              <div className="app-image-upload">
                <div className="image-upload-section d-flex">
                  <div
                    className="p-1 p-lg-2"
                    onMouseOver={() => setShowDelete(true)}
                    onMouseLeave={() => setShowDelete(false)}
                  >
                    {showDelete && imgSrc ? (
                      <div className="delete-image-upload">
                        <img
                          src={IMAGES.trash_white_icon}
                          className="mb-1 cursor-pointer"
                          width="40"
                          alt=""
                          onClick={() => {setImgSrc(null); window.analytics('Delete_Image',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {mode === "create" ? (
                      <img
                        src={imgSrc ? imgSrc : IMAGES.image_upload}
                        alt="img upload"
                        width={130}
                        height={130}
                      />
                    ) : (
                      <img
                        src={
                          imgSrc
                            ? imgSrc
                            : initialValues.image
                            ? initialValues.image
                            : IMAGES.default_app_icon
                        }
                        alt="Upload"
                        width={130}
                        height={130}
                      />
                    )}
                  </div>
                  <div className="image-selection-container ml-2 ">
                    <h4 className="image-upload-header">Upload an Image</h4>
                    <p className="image-upload-description mb-0">
                      Add an image for app Icon
                    </p>
                    <Upload {...imageProps} maxCount={1}>
                      <Button className="btn btn-primary btn-sm d-flex mt-3">
                        {mode === "create" ? "Browse" : "Change"}
                      </Button>{" "}
                    </Upload>
                  </div>
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="appName"
              rules={[
                {
                  required: true,
                  message: "Please enter app name",
                },
              ]}
            >
              <input
                className="form-control"
                placeholder="App name*"
                height="30"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="redirectUrl"
              rules={[
                {
                  required: true,
                  message: "Please enter redirect URL",
                },
                {
                  pattern: new RegExp(
                    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
                  ),
                  message: "Please enter a valid URL",
                },
              ]}
            >
              <input
                className="form-control"
                placeholder="Redirect URL*"
                rows="4"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="description"
              rules={[
                {
                  max: 180,
                  message: "Description should be less than 180 characters",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Description"
                className="form-control"
                autoSize={{
                  minRows: 4,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {mode === "edit" && (
          <Row>
            <Form.Item name="generatePassword" valuePropName="checked">
              <Checkbox>Regenerate Secret ID</Checkbox>
            </Form.Item>
          </Row>
        )}
        <p className="item-description">Webhooks/Postbacks (Optional)</p>
        <Form.List
          name="webhook"
          initialValue={[{ fieldKey: 0, isListField: true, key: 0, name: 0 }]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, i) => (
                <Collapse
                  key={i}
                  accordion
                  className="collapse-item"
                  expandIcon={customExpandIcon}
                  defaultActiveKey={mode === "create" ? "0" : ""}
                >
                  <Panel
                    header={`Webhook ${i + 1}`}
                    key={i}
                    extra={panelOptions(remove, name)}
                    className="mb-2"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "WEBHOOK_URL"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter webhook url",
                          },
                          {
                            pattern: new RegExp(
                              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
                            ),
                            message: "Please enter a valid URL",
                          },
                        ]}
                      >
                        <input
                          className="form-control whites"
                          placeholder="Webhook URL"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "WEBHOOK_SECRET"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter webhook secret",
                          },
                        ]}
                      >
                        <input
                          className="form-control"
                          placeholder="Webhook Secret"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "WEBHOOK_PREFERENCE"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select webhook preference",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Webhook Preference"
                          size="large"
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {selectWork.map((item, i) => (
                            <Option value={item.value} key={i}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Panel>
                </Collapse>
              ))}
              <Form.Item>
                <button
                  className="btn btn-link add-webhook-button"
                  onClick={(event) => {
                    event.preventDefault();
                    add();
                    window.analytics('Add_Webhook',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)
                  }}
                >
                  + Add Webhook
                </button>
              </Form.Item>
            </>
          )}
        </Form.List>
        {mode === "create" && (
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            className="mb-3"
            value={selectedPermissions}
            onChange={handlePermissionsChange}
          >
            <p className="item-description">App Permissions</p>

            <Row className="container permissions-container py-2">
              <Col>
                <Checkbox
                  value="x:0"
                  className="mt-2 mt-md-3 mb-2 mb-md-3"
                  disabled={
                    selectedPermissions.includes("x:2") ||
                    selectedPermissions.includes("x:1")
                  }
                >
                  Profile Details
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  value="x:1"
                  className="mt-2 mt-md-3 mb-2 mb-md-3"
                  disabled={selectedPermissions.includes("x:2")}
                >
                  Transaction Info
                </Checkbox>
              </Col>
              <Col>
                <Checkbox value="x:2" className="mt-2 mt-md-3 mb-2 mb-md-3">
                  Order Placement
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  value="d:1"
                  className="mt-2 mt-md-3 mb-2 mb-md-3"
                  disabled={selectedPermissions.includes("d:2")}
                >
                  Quotes & Market data
                </Checkbox>
              </Col>
              <Col>
                <Checkbox value="d:2" className="mt-2 mt-md-3 mb-2 mb-md-3">
                  Historical Data
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        )}
        {mode === "create" && (
          <Row className="container-fluid">
            <Form.Item
              name="terms"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  transform: (value) => value || undefined,
                  type: "boolean",
                  message: "Please accept the terms and conditions",
                },
              ]}
            >
              <Checkbox>
                I accept the API usage{" "}
                <a
                  href="https://fyers.in/terms-and-conditions-api/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </Checkbox>
            </Form.Item>
          </Row>
        )}
      </Form>
    </Drawer>
  );
}
export default CreateEditDrawer;
