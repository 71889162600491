/**
 * [deleteCookie Deletes a cookie]
 * @param  {String} name
 * @param  {String} path
 * 
 * @param  {String} domain
 * @return {void}
 */
export default function deleteCookie(name) {
  if (document.cookie.indexOf(name) >= 0) {
    document.cookie =
      name +
      "=-1;secure;expires=Thu, 01 Jan 2018 12:00:00 UTC;path=/;domain=.fyers.in";
  }
}
