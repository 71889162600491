import IMAGES from "../../Constants/images";
import CONSTANTS from "../../Constants/static";

export default function MinimalFooter() {
  return (
    <footer>
      <div className="bottom-details">
        <div className="bottom_text ">
          <span className="footer-text-item">
            &copy; Fyers Securities Pvt. Ltd. All Rights Reserved.
          </span>
          <span>
            <a
              href="https://fyers.in/terms-and-conditions.php"
              target="_blank"
              rel="noreferrer"
              className="footer-text-item"
              onClick={()=>window.analytics('Footer_Terms_and_Conditions',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
            >
              Terms &amp; Conditions
            </a>
            <a
              href="https://fyers.in/privacy-policy.php"
              target="_blank"
              rel="noreferrer"
              className="footer-text-item"
              onClick={()=> window.analytics('Footer_Privacy_Policy',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
            >
              Privacy Policy
            </a>
            <a
              href="https://support.fyers.in"
              target="_blank"
              rel="noreferrer"
              className="footer-text-item"
              onClick={()=>window.analytics('Footer_support',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)}
            >
              Support{" "}
              <img
                className="footer-open-icon"
                src={IMAGES.external_website_icon}
                alt="open support portal"
                height="10"
              />
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}
