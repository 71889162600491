const assetsDomain = "https://assets.fyers.in";

export default Object.freeze({
  fyers_loader: `${assetsDomain}/global-components/loader/fyers_loader_transparent.gif`,
  myapi_logo: `${assetsDomain}/images/Fyers-Myapi/fyers-api-logo.svg`,
  navbar_grid_icon: `${assetsDomain}/global-components/navbar/icon-grid-menu.svg`,
  navbar_logout_icon: `${assetsDomain}/images/global-header/icon-logout.svg`,
  navbar_hamburger_menu: `${assetsDomain}/global-components/navbar/hamburger-menu.svg`,
  navbar_user_icon: `${assetsDomain}/global-components/navbar/user.svg`,
  navbar_product_close_icon: `${assetsDomain}/images/global-header/close-menu.svg`,
  navbar_product_arrow_icon: `${assetsDomain}/images/website/icon-arrow-right-header.svg`,
  navbar_product_empty: `${assetsDomain}/images/global-header/empty-product-list.svg`,
  navbar_down_arrow_icon: `${assetsDomain}/images/website/icon-down-arrow.svg`,
  external_website_icon: `${assetsDomain}/images/website/icon-open-blank.svg`,
  close_menu_icon: `${assetsDomain}/myapi-dashboard/dashboard/close-menu.svg`,
  edit_app_icon: `${assetsDomain}/myapi-dashboard/dashboard/icon-edit.svg`,
  revoke_app_icon: `${assetsDomain}/myapi-dashboard/dashboard/icon-revoke.svg`,
  delete_app_icon: `${assetsDomain}/myapi-dashboard/dashboard/icon-delete.svg`,
  default_app_icon: `${assetsDomain}/myapi-dashboard/dashboard/default.svg`,
  calender_small_icon: `${assetsDomain}/images/website/webinars/icon-calendar-small.svg`,
  next_link_icon: `${assetsDomain}/images/website/icon-link-next.svg`,
  options_dot_icon: `${assetsDomain}/myapi-dashboard/dashboard/options.svg`,
  down_arrow_icon: `${assetsDomain}/myapi-dashboard/dashboard/down-arrow-active.svg`,
  down_arrow_inactive_icon: `${assetsDomain}/myapi-dashboard/dashboard/down-arrow-inactive.svg`,
  trash_white_icon: `${assetsDomain}/myapi-dashboard/dashboard/icon-trash-white.svg`,
  image_upload: `${assetsDomain}/myapi-dashboard/dashboard/image-uplod.svg`,
  delete_confirm_icon: `${assetsDomain}/myapi-dashboard/dashboard/delete-app-icon.svg`,
  revoke_confirm_icon: `${assetsDomain}/myapi-dashboard/dashboard/revoke-app-icon.svg`,
});
