import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import InitializeReactGA from "../Helpers/googleAnalytics";

/**
 * [usePageViews Google analytics to track routes visited by user]
 * @param  {Object} ReactGA
 * @return {void}
 */
export function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    InitializeReactGA(ReactGA);
    ReactGA.pageview(location.pathname);
  }, [location]);
}
