import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { setLoading } from "../../Store/slice/apiListSlice";
import { deleteApp } from "../../Services/API/api";
import fyersToaster from "../../Helpers/fyersToaster";
import destructureError from "../../Helpers/destructureErrorMessage";
import IMAGES from "../../Constants/images";
import CONSTANTS from "../../Constants/static";

export default function DeleteModal({
  setModalVisible,
  getApps,
  initialValues,
}) {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    window.analytics('Delete_App',CONSTANTS.google_analytics_event_category,CONSTANTS.google_analytics_event_type)
    setModalVisible(false);
    dispatch(setLoading(true));
    try {
      const payload = {
        appId: `${initialValues.appId}`,
      };
      await deleteApp(payload);
      const successMessage = {
        type: "success",
        title: "Success",
        desc: `Successfully deleted ${initialValues.appName}`,
      };
      fyersToaster(successMessage);
      getApps();
    } catch (error) {
      const errorText = destructureError(error);
      const errorMessage = {
        type: "error",
        title: "Error",
        desc: errorText,
      };
      fyersToaster(errorMessage);
      dispatch(setLoading(false));
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      title={
        <div>
          {" "}
          <img
            src={IMAGES.delete_confirm_icon}
            className="mb-1 mr-2"
            width="22"
            alt=""
          />
          Delete App Confirmation
        </div>
      }
      open={true}
      onOk={handleDelete}
      onCancel={handleModalCancel}
      okText="Delete"
      className="delete-modal"
    >
      <p>Are you sure you want to delete {initialValues.appName} ?</p>
    </Modal>
  );
}
